/*
 * Wrapper component for linking to an external page
 */

import React from "react";
import PropTypes from "prop-types";

const LinkExternal = ({ children, href }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);

LinkExternal.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired
};

export default LinkExternal;
