import Button from "./Button";
import Footer from "./Footer";
import GlobalStyles from "./GlobalStyles";
import HeadingXL from "./HeadingXL";
import Image from "./Image";
import Layout from "./Layout";
import Nav from "./Nav";
import SEO from "./SEO";
import TextBody from "./TextBody";
import SlideShow from "./SlideShow";

export {
  Button,
  Footer,
  GlobalStyles,
  HeadingXL,
  Image,
  Layout,
  Nav,
  SEO,
  TextBody,
  SlideShow
};
