import React from "react";
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl";
import styled from "styled-components";

const languageName = {
  en: "EN",
  de: "DE"
};

const LangLink = styled.a`
  padding: 1rem;
  color: ${props => props.theme.secondary};
  text-decoration: ${props => (props.active ? "underline" : "none")};

  :hover {
    cursor: pointer;
    background: ${props => props.theme.secondary};
    color: ${props => props.theme.primary};
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Language = () => {
  return (
    <Container>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
          languages.map(language => (
            <LangLink
              key={language}
              onClick={() => changeLocale(language)}
              active={language === currentLocale}
            >
              {languageName[language]}
            </LangLink>
          ))
        }
      </IntlContextConsumer>
    </Container>
  );
};

export default Language;
