import React from "react";
import CookieConsent from "react-cookie-consent";
import { injectIntl } from "gatsby-plugin-intl";
import colors from "../theme/colors";

const CookieConsentBanner = ({ intl }) => (
  <CookieConsent
    location="bottom"
    buttonText={intl.formatMessage({ id: "cookie_consent_accept" })}
    enableDeclineButton
    declineButtonText={intl.formatMessage({ id: "cookie_consent_decline" })}
    cookieName="gatsby-gdpr-google-analytics"
    onAccept={() => {
      window.location.reload();
    }}
  >
    <span>{intl.formatMessage({ id: "cookie_consent_text" })}</span>
  </CookieConsent>
);

export default injectIntl(CookieConsentBanner);
