import React from "react";
import { Link } from "gatsby";
import { FormattedMessage } from "gatsby-plugin-intl";
import styled from "styled-components";

import { Row } from "./styled";

import LinkExternal from "./linkext";

/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "[iL]ogo" }]*/
import pitztalLogoURL, {
  ReactComponent as PitztalLogo
} from "../../assets/logo_pitztal.svg";
import interhomeLogoURL, {
  ReactComponent as InterhomeLogo
} from "../../assets/interhome_logo.svg";

const StyledFooter = styled.footer`
  background: ${props => props.theme.primary};
  padding: 1.45rem 1.4875rem;
  color: ${props => props.theme.secondary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const StyledLink = styled(props => <Link {...props} />)`
  color: ${props => props.theme.secondary};
  padding: 10px;
  font-size: 1.3rem;
  :hover {
    color: ${props => props.theme.tertiary};
  }
`;
const LogoWrapper = styled.img`
  height: 3rem;
  width: auto;
  margin: 1rem;
`;

const Footer = () => {
  return (
    <StyledFooter>
      <Row>
        <LinkExternal href="https://www.pitztal.com/">
          <LogoWrapper src={pitztalLogoURL} alt="Pitztal Logo" />
        </LinkExternal>
        <LinkExternal href="https://www.interhome.at/oesterreich/pitztal/sankt-leonhard-im-pitztal/ferienhaus-bergkristall-pzt360-at6481.604.1">
          <LogoWrapper src={interhomeLogoURL} alt="Interhome Logo" />
        </LinkExternal>
      </Row>

      <Row>
        <StyledLink to={"/legal-notice"}>
          <FormattedMessage id="legal_notice" />
        </StyledLink>
        <StyledLink to={"/privacy-policy"}>
          <FormattedMessage id="privacy_policy" />
        </StyledLink>
      </Row>

      <div>© {new Date().getFullYear()}</div>
    </StyledFooter>
  );
};

export default Footer;
