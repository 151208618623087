import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import { injectIntl } from "gatsby-plugin-intl";
import ScrollUpButton from "react-scroll-up-button";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import { slide as Menu } from "react-burger-menu";

import theme from "../theme/colors";

import Header from "./header/";
import Footer from "./Footer";
import { Column } from "./styled";
import PictureCarousel from "./PictureCarousel";
import NavLink from "./header/navlink";
import CookieConsentBanner from "./CookieConsentBanner";

const styles = {
  bmBurgerButton: {
    position: "fixed",
    width: "36px",
    height: "30px",
    left: "36px",
    top: "36px"
  },
  bmBurgerBars: {
    background: "#373a47"
  },
  bmBurgerBarsHover: {
    background: "#a90000"
  },
  bmCrossButton: {
    height: "24px",
    width: "24px"
  },
  bmCross: {
    background: "#bdc3c7"
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%"
  },
  bmMenu: {
    background: theme.primary,
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em",
    overflow: "hidden"
  },
  bmMorphShape: {
    fill: "#373a47"
  },
  bmItemList: {
    color: "#b8b7ad",
    padding: "0.8em"
  },
  bmItem: {
    display: "inline-block"
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)"
  }
};

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 25px;
  }
  body {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    font-family: 'Karla', Lato, "Lucida Grande", Tahoma, Sans-Serif;
    background: ${props => props.theme.background};
    color: #575757
  }
  a {
    text-decoration: none;
  }
`;

const Container = styled(Column)`
  margin: 0 auto;
  background: ${props => props.theme.background};
`;

const Content = styled.main``;

const MenuWrapper = styled.div`
  @media (min-width: 1225px) {
    display: none;
  }
`;

const Layout = ({ children, intl, carousel }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={() => {
      return (
        <ThemeProvider theme={theme}>
          <div>
            <GlobalStyle />
            <MenuWrapper>
              <Menu width={"90%"} styles={styles}>
                <NavLink to={"/"} messageId={"welcome"} />
                <NavLink to={"/location"} messageId={"how_to_find_us"} />
              </Menu>
            </MenuWrapper>
            <Header siteTitle={intl.formatMessage({ id: "title" })} />
            {carousel && <PictureCarousel />}
            <Container>
              <Content>{children}</Content>
              <ScrollUpButton
                StopPosition={0}
                ShowAtPosition={150}
                EasingType="easeOutCubic"
                AnimationDuration={500}
                ContainerClassName="ScrollUpButton__Container"
                TransitionClassName="ScrollUpButton__Toggled"
              />
            </Container>
            <Footer />
            <CookieConsentBanner />
          </div>
        </ThemeProvider>
      );
    }}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default injectIntl(Layout);
