import React from 'react';
import { Link, FormattedMessage } from 'gatsby-plugin-intl';
import styled from 'styled-components';

const NavItem = styled(props => <Link {...props} />)`
  :hover {
    background: ${props => props.theme.secondary};
    color: ${props => props.theme.primary};
  }
  background: ${props => props.theme.primary};
  color: ${props => props.theme.secondary};
  font-size: 1.4rem;
  padding: 1rem;
  display: block;
`;

const ListItem = styled.li``;

const NavLink = ({ to, messageId }) => (
  <NavItem to={to} role='button'>
    <FormattedMessage id={messageId} />
  </NavItem>
);

export default NavLink;
