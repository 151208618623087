import React from 'react';
import styled from 'styled-components';

import NavLink from './navlink';

const List = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  order: 2;
  margin: 0;
  padding: 0;
  list-style-type: none;
  @media only screen and (max-width: 550px) {
    flex-direction: column;
  }
`;

const Navbar = () => {
  return (
    <List>
      <NavLink to={'/'} messageId={'welcome'} />
      <NavLink to={'/location'} messageId={'how_to_find_us'} />
    </List>
  );
};

export default Navbar;
