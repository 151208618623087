import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby-plugin-intl';
import styled from 'styled-components';
import {Row} from '../styled';
import Image from '../Image';
import Language from '../language';
import Navbar from './navbar';

const Container = styled(Row)`
  background: ${props => props.theme.primary};
  justify-content: space-around;
  align-items: flex-end;
  padding: 0 1rem;
  @media only screen and (max-width: 1224px) {
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
    padding: 0 1rem 1rem 0;
  }
`;

const Heading = styled(Link)`
  order: 1;
  min-width: 300px;
`;

const NavbarWrapper = styled.div`
  order: 2;
  @media (max-width: 1224px) {
    display: none;
  }
`;

const LangWrapper = styled.div`
  order: 3;
  @media (max-width: 1224px) {
    order: 2;
  }
`;

const Header = ({siteTitle}) => {
  return (
    <Container>
      <Heading to={'/'}>
        <Image imgName='logo2.png' alt='Logo' />
      </Heading>

      <NavbarWrapper>
        <Navbar />
      </NavbarWrapper>

      <LangWrapper>
        <Language />
      </LangWrapper>
    </Container>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ''
};

export default Header;
