import styled from "styled-components";

export default styled.h1`
  display: block;
  margin: 0 auto 10vh auto;
  font-size: 80px;
  line-height: 1.2;
  text-align: center;
  font-weight: 700;
  letter-spacing: -4px;
  background: black;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 849px) {
    font-size: 45px;
  }
`;
