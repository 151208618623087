import styled from 'styled-components'

export default styled.div`
  margin-right: auto;
  margin-left: auto;

  max-width: 960px;

  padding-right: 1em;
  padding-left: 1em;
`
