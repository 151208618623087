const colors = {
  main: "#393276",
  dark: "#0D083B",
  light: "#837EB1",
  primary: "#1b1b1e",
  secondary: "#bfbfbf",
  tertiary: "#bfbfbf",
  background: "#bfbfbf"
};

export default colors;
