import React from "react";
import Helmet from "react-helmet";
import { injectIntl } from "gatsby-plugin-intl";

const SEO = ({ intl, title }) => {
  return (
    <Helmet
      htmlAttributes={{ lang: `${intl.formatMessage({ id: "lang" })}` }}
      title={title}
      titleTemplate={`%s | ${intl.formatMessage({ id: "title" })}`}
      meta={[
        {
          name: "description",
          content: `${intl.formatMessage({ id: "description" })}`
        },
        {
          property: "og:title",
          content: `${intl.formatMessage({ id: "title" })}`
        },
        {
          property: "og:description",
          content: `${intl.formatMessage({ id: "description" })}`
        },
        {
          property: "author",
          content: `${intl.formatMessage({ id: "author" })}`
        }
      ]}
    >
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  );
};

const schema = {
  "@context": "http://schema.org",
  "@type": "Hotel",
  name: "Haus Bergkristall",
  description:
    "Das Haus Bergkristall im wunderschönen Pitztal. Ein Selbstversorgerhaus mit wunderschönem Blick auf die Berge, ganz nah am Gletscher. Perfekter Ausgangspunkt zum Schifahren und zum Wandern. ",
  telephone: "+43541386358",
  email: "info@haus-bergkristall.at",
  url: "https://haus-bergkristall.at",
  logo:
    "https://haus-bergkristall.at/static/97ea59f1cde6c85b605e95b299c21019/2a0a8/logo2.png",
  founder: "Familie Schütz",
  address: {
    "@type": "PostalAddress",
    addressCountry: "AT",
    addressLocality: "St. Leonhard",
    addressRegion: "Tirol",
    postalCode: "6481",
    streetAddress: "Plangeross 70"
  },
  geo: {
    "@type": "GeoCoordinates",
    latitude: "46.9870612",
    longitude: "10.8671298"
  },
  image:
    "https://haus-bergkristall.at/static/4efb11b7d4dc0d0cd9a2623a0c3f3b1e/e23d9/au%C3%9Fen1.jpg"
};

export default injectIntl(SEO);
