import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import Carousel from "nuka-carousel";

const StyledCarousel = styled(Carousel)`
  min-height: 200px;
  overflow: hidden;
`;

const PictureCarousel = () => {
  const data = useStaticQuery(graphql`
    query CarouselQuery {
      allFile(
        filter: {
          relativeDirectory: { eq: "house" }
          name: { regex: "/außen/" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
                originalName
              }
            }
          }
        }
      }
    }
  `);

  const images = data.allFile.edges;

  return (
    <StyledCarousel
      autoplay
      speed={2000}
      transitionMode={"fade"}
      wrapAround
      withoutControls
    >
      {images.map(image => {
        return (
          <Img
            key={image.node.childImageSharp.fluid.originalName}
            fluid={image.node.childImageSharp.fluid}
            imgStyle={{ objectFit: "contain" }}
          />
        );
      })}
    </StyledCarousel>
  );
};

export default PictureCarousel;
